import React from "react"
import css from "@emotion/css"

import { BLOCKS } from "@contentful/rich-text-types"
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Seo from "../components/Seo"

const PartnersPage = ({ data }) => {
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        console.log(node)

        const file =
          node.data &&
          node.data.target &&
          node.data.target.fields &&
          node.data.target.fields.file
        return file ? (
          <img className="mx-auto my-6" src={file["en-US"].url} />
        ) : (
          <>IMAGE NOT FOUND</>
        )
      },
    },
  }
  return (
    <>
      <Seo
        data={{
          title: data.page.seo && data.page.seo.title,
          description: data.page.seo && data.page.seo.description,
        }}
      ></Seo>
      <div className="py-4 bg-blue-800 text-white">
        <div className="container">
          {" "}
          <h1 className="text-4xl uppercase leading-tight my-0">
            Partner Program
          </h1>
        </div>
      </div>

      <div className="bg-brand-light2">
        <div className="container py-6 sm:py-12 flex flex-col sm:flex-row items-start">
          <div id="content" className="w-full md:w-2/3 max-w-4xl  pr-4">
            {documentToReactComponents(data.page.content.json)}
            <div className="flex md:flex-row flex-col mt-6">
              <div
                className={"w-full md:w-1/2 "}
                css={css`
                  h3 {
                    font-size: 1.5rem;
                    font-weight: 600;
                  }
                `}
              >
                {documentToReactComponents(data.page.partners1.json)}
              </div>
              <div
                className="w-full md:w-1/2 "
                css={css`
                  h3 {
                    font-size: 1.5rem;
                    font-weight: 600;
                  }
                `}
              >
                {documentToReactComponents(data.page.partners2.json)}
              </div>
            </div>
          </div>
          <div
            className="  w-full md:w-1/2 lg:w-5/12   xl:w-1/3 lg:flex-grow mt-6 sm:mt-0 text-center sidebar-content"
            css={css`
              p {
                text-transform: uppercase;
                font-weight: 600;
              }
            `}
          >
            {documentToReactComponents(data.page.sidebar.json, options)}
          </div>
        </div>
      </div>
    </>
  )
}

export default PartnersPage

export const query = graphql`
  {
    page: contentfulPartnerProgramPage {
      title
      seo {
        title
        description
      }
      content {
        json
      }
      partners1 {
        json
      }
      partners2 {
        json
      }
      sidebar {
        json
      }
    }
  }
`
